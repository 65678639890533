<template>
    <div
        class="model"
        :style="'background-image:url(' + modelType[curType].bg + ')'"
    >
        <div class="auto-1440">
            <Breadcrumb :title="modelInfo.Name" />
            <div class="title">
                <h1>{{ modelInfo.Name }}</h1>
                <div class="goback">
                    <span @click="goBack">回到详情页</span>
                    <svg-icon icon-class="icon_more"></svg-icon>
                </div>
            </div>
            <div class="content">
                <div class="type">
                    <div
                        class="item"
                        :class="item.value == curType ? 'active' : ''"
                        v-for="item in modelType"
                        :key="item.value"
                        @click="changeType(item)"
                    >
                        {{ item.name }}
                    </div>
                </div>

                <div class="tree">
                    <a-spin size="large" :spinning="loading">
                        <template v-for="item in modelType">
                            <Tree
                                :type="item"
                                v-show="curType == item.value"
                                :key="item.value"
                                :data="item.tree"
                                :title="modelInfo.Name"
                            />
                        </template>
                    </a-spin>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb';
import Tree from '@/components/Learning/Model/Tree';
import { mapState, mapActions, mapMutations } from 'vuex';
import { transformTreeData, filterTreeData } from '@/assets/js/common';

import { GetData, SetHours } from '@/api/model';
import {
    GetAllListByModelId,
    GetExamListByModelId,
    GetKnowledgeListByModelId,
    GetNoteListByModelId,
} from '@/api/card';
import { AddOrder } from '@/api/order';
import vueQr from 'vue-qr';
export default {
    metaInfo() {
        return {
            title:
                this.modelInfo.SEOTitle || this.modelInfo.Name || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.modelInfo.SEOKeyword,
                },
                {
                    name: 'description',
                    content: this.modelInfo.SEODescription,
                },
            ],
        };
    },
    components: {
        Breadcrumb,
        Tree,
        vueQr,
    },
    computed: {
        curTypeItem() {
            return this.modelType.find(item => item.value == this.curType);
        },
        modelType() {
            return JSON.parse(JSON.stringify(this.$store.getters.modelType));
        },
    },
    data() {
        return {
            curType: 0,
            modelInfo: {},
            modelId: '',
            loading: false,
            // 接口
            getTree: {
                GetExamListByModelId: GetExamListByModelId,
                GetKnowledgeListByModelId: GetKnowledgeListByModelId,
                GetNoteListByModelId: GetNoteListByModelId,
            },
            treeData: [],
            isStart: true,
            setHoursTimer: null,
            time: 1000 * 5 * 60,
        };
    },
    methods: {
        async getData(id) {
            const { data } = await GetData({ id: id });
            this.modelInfo = data;
        },
        changeType(item) {
            if (this.curType == item.value || this.loading) return;
            this.curType = item.value;
            this.$forceUpdate();
            // this.getModelTree()
            console.log(this.modelId, this.curType);
            this.isStart = true;
            this.setHours();
        },
        // 获取列表
        // async getModelTree() {
        //     if (this.curTypeItem.tree) return
        //     this.loading = true
        //     await this.getTree[this.curTypeItem.request]({
        //         modelId: this.modelId,
        //     })
        //         .then(res => {
        //             this.curTypeItem.tree = transformTreeData(res.data)
        //         })
        //         .catch(e => {
        //             console.log(e)
        //         })
        //     this.loading = false
        // },
        async getModelTree() {
            this.loading = true;

            await GetAllListByModelId({
                modelId: this.modelId,
            })
                .then(res => {
                    this.modelType.forEach(function (item) {
                        filterTreeData(res.data, item.ids).then(res => {
                            item.tree = transformTreeData(res);
                        });
                    });
                    this.setHours();
                    // console.log(treeData , 'tree');
                })
                .catch(e => {
                    console.log(e);
                });
            this.loading = false;
        },
        // 回到详情页
        goBack() {
            this.$router.push({
                name: 'learning-info',
                params: { id: this.$route.params.id },
            });
        },
        // 上传浏览记录接口
        async setHours(isLeaving) {
            console.log(1);
            await SetHours({
                modelId: this.modelId,
                type: this.curType,
                isStart: this.isStart,
            })
                .then(res => {
                    this.isStart = false;
                })
                .catch(e => e);
            if (!isLeaving) {
                this.setHoursTask();
            } else {
                clearInterval(this.timer);
            }
        },
        setHoursTask() {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.setHours();
            }, this.time);
        },
        addOrder() {
            AddOrder({ modelId: this.modelId })
                .then(res => {
                    console.log(res);
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    created() {
        this.modelId = this.$route.params.id;
        this.curType = this.$route.query.type;
        this.getData(this.modelId);
        this.getModelTree();
        this.addOrder();
    },
    beforeRouteLeave(to, from, next) {
        this.setHours(true);
        next();
    },
};
</script>
<style scoped lang="less">
.model {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    // flex: 1;
    // padding-bottom: 60px;
    transition: 0.3s;
    background-color: #000;

    & > .auto-1440 {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .title,
    /deep/.breadcrumb {
        flex-shrink: 0;
    }
}

.title {
    display: flex;
    color: #fff;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: flex-end;

    h1 {
        color: #fff;
        font-size: 1.25em;
        line-height: 35px;
        line-height: 1.35;
    }

    .goback {
        line-height: 35px;
        font-size: 1em;
        color: #fff;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
        transition: 0.3s;

        .svg-icon {
            font-size: (9 / 16em);
            margin-left: 10px;
        }

        &:hover {
            color: #f49133;
        }
    }
}

.content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .type {
        position: absolute;
        right: calc(100% + 5px);
        top: 0;
        display: flex;
        flex-direction: column;

        .item {
            padding: 0 10px;
            line-height: 30px;
            text-align: center;
            white-space: nowrap;
            background-color: rgba(255, 255, 255, 0.2);
            margin-bottom: 5px;
            border-radius: 0 10px 0 10px;
            color: rgba(255, 255, 255, 0.6);
            transition: 0.3s;
            font-size: 14px;
            cursor: pointer;
            font-weight: bold;

            &.active,
            &:hover {
                color: #000;
                background-color: #00ffc0;
            }
        }
    }

    .tree {
        flex: 1;
        flex-shrink: 1;
        padding-bottom: 40px;

        /deep/.ant-spin-container,
        /deep/.ant-spin-nested-loading {
            height: 100%;
        }
    }
}

.qrdode {
    position: absolute;
    left: calc(100% + 10px);
    z-index: 2;
    bottom: 40px;
    color: #fff;
    text-align: center;
    white-space: nowrap;

    img {
        border-radius: 4px;
        margin-bottom: 10px;
        width: 150px;
        display: block !important;
        margin: 0 auto 10px;
    }
}

@media (max-width: 1024px) {
    .content {
        .type {
            display: none;
        }
    }

    .tab {
        display: none;
    }

    .qrdode {
        display: none;
    }

    .goback {
        display: none !important;
    }

    /deep/.breadcrumb {
        display: none !important;
    }
}
</style>
