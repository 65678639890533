<template>
    <div class="filter">
        <h2>批注</h2>
        <div class="search-box">
            <a-select v-model="defaultValue">
                <a-select-option
                    v-for="item in searchType"
                    :key="item.value"
                    :value="item.key"
                >
                    {{ item.name }}
                </a-select-option>
            </a-select>
            <a-input-search
                v-model="value"
                placeholder="输入关键词搜索"
                @search="onSearch"
            />
        </div>
        <div class="result">
            <div>
                <span>关键词：</span>
                <span class="red">{{ value }}</span>
            </div>
            <div>
                <span>检索结果：</span>
                <span>{{ total }} 个结果</span>
            </div>
        </div>
        <div class="list">
            <a-list
                item-layout="horizontal"
                :data-source="result"
                :loading="loading"
            >
                <a-list-item
                    slot="renderItem"
                    slot-scope="item"
                    @click.native="scrollToEle(item)"
                >
                    <div class="title dot" v-html="item.Title"></div>
                    <div class="des dot2" v-html="item.Content"></div>
                </a-list-item>
            </a-list>
            <Pagination
                :pageSize="params.pageSize"
                :total="total"
                @pageChange="pageChange"
                v-if="result.length > 0"
                :current="params.pageIndex"
            />
        </div>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetListByModelId } from '@/api/remark'
export default {
    props: ['curType', 'treeData', 'type'],
    components: {
        Pagination,
    },
    data() {
        return {
            total: 0,
            params: {
                modelId: '',
                pageIndex: 1,
                pageSize: 5,
            },
            result: [],
            cardType: [],
            loading: false,
            searchType: [
                {
                    name: '全文',
                    value: 0,
                    key: 'fullText',
                },
                {
                    name: '标题',
                    value: 1,
                    key: 'title',
                },
                {
                    name: '标签',
                    value: 2,
                    key: 'tags',
                },
                {
                    name: '来源',
                    value: 3,
                    key: 'source',
                },
                {
                    name: '内容',
                    value: 4,
                    key: 'content',
                },
            ],
            defaultValue: 'fullText',
            value: '',
        }
    },
    methods: {
        onSearch() {
            this.params.pageIndex = 1
            let form = JSON.parse(JSON.stringify(this.params))
            this.$set(form, this.defaultValue, this.value)
            console.log(form)
            this.getList(form)
        },

        async getList(params) {
            this.loading = true
            await GetListByModelId(params)
                .then(res => {
                    console.log(res)
                    const { data, total } = res
                    // if (this.value) {
                        this.$emit('hightlight', this.value)
                        data.forEach(element => {
                            element.Title = element.Title.replace(
                                new RegExp(this.value, 'g'),
                                "<span style='color:red;'>" +
                                    this.value +
                                    '</span>'
                            )
                            element.Content = element.Content.replace(
                                new RegExp(this.value, 'g'),
                                "<span style='color:red;'>" +
                                    this.value +
                                    '</span>'
                            )
                        })
                    // }
                    this.result = data
                    this.total = total
                })
                .catch(e => e)
            this.loading = false
        },
        pageChange({ page, pageSize }) {
            this.params.pageIndex = page
            this.getList()
        },
        // 跳转到模型内的相应部分
        scrollToEle(item) {
            const { CardId } = item
            const ele = $('#' + this.type + CardId)
            const parent = ele.parents('.model_main')
            parent.animate({ scrollTop: ele[0].offsetTop }, 500)
        },
    },
    created() {
        this.params.modelId = this.$route.params.id
        this.getList(this.params)
    },
}
</script>
<style scoped lang="less">
@import '~@/assets/css/right.less';
</style>