<template>
    <div class="filter">
        <h2>检索</h2>
        <div class="search-box">
            <a-select v-model="defaultValue">
                <a-select-option
                    v-for="item in searchType"
                    :key="item.vale"
                    :value="item.value"
                >
                    {{ item.name }}
                </a-select-option>
            </a-select>
            <a-input-search
                v-model="params.key"
                placeholder="输入关键词搜索"
                @search="onSearch"
            />
        </div>
        <div class="result">
            <div>
                <span>关键词：</span>
                <span class="red">{{ params.key }}</span>
            </div>
            <div>
                <span>检索结果：</span>
                <span>{{ total }} 个结果</span>
            </div>
        </div>
        <div class="list">
            <a-list
                item-layout="horizontal"
                :data-source="result"
                :loading="loading"
            >
                <a-list-item
                    slot="renderItem"
                    slot-scope="item"
                    @click.native="scrollToEle(item)"
                >
                    <div class="title dot" v-html="item.label"></div>
                    <div class="des dot2" v-html="item.Content"></div>
                </a-list-item>
            </a-list>
        </div>
    </div>
</template>
<script>
import {
    GetAllListByModelId,
    GetExamListByModelId,
    GetKnowledgeListByModelId,
    GetNoteListByModelId,
} from '@/api/card'
export default {
    props: ['curType', 'treeData'],
    data() {
        return {
            total: 0,
            params: {
                modelId: '',
                key: '',
                isTree: false,
            },
            result: [],
            cardType: [],
            loading: false,
            searchType: [
                {
                    name: '全文',
                    value: 0,
                },
                {
                    name: '标题',
                    value: 1,
                },
                {
                    name: '内容',
                    value: 2,
                },
                {
                    name: '标签',
                    value: 3,
                },
            ],
            defaultValue: 0,
            getTree: {
                GetExamListByModelId,
                GetKnowledgeListByModelId,
                GetNoteListByModelId,
            },
        }
    },
    methods: {
        onSearch() {
            // if (!this.params.key) return
            this.$emit('hightlight', this.params.key)
            this.params.key = String(this.params.key)
            if (!this.params.key) {
                this.result = []
                this.total = 0
                return
            }
            let arr = []
            // 全文
            this.loopTreeData(this.treeData, element => {
                let itemTemp = JSON.parse(JSON.stringify(element))
                itemTemp.label = itemTemp.label || ''
                itemTemp.Content = itemTemp.Content || ''
                itemTemp.card.Tags = itemTemp.card.Tags || ''
                if (this.defaultValue == 0) {
                    if (
                        itemTemp.label.indexOf(this.params.key) !== -1 ||
                        itemTemp.Content.indexOf(this.params.key) !== -1 ||
                        itemTemp.card.Tags.indexOf(this.params.key) !== -1
                    ) {
                        itemTemp.label = itemTemp.label.replace(
                            new RegExp(this.params.key, 'g'),
                            "<span style='color:red;'>" +
                                this.params.key +
                                '</span>'
                        )
                        itemTemp.Content = itemTemp.Content.replace(
                            new RegExp(this.params.key, 'g'),
                            "<span style='color:red;'>" +
                                this.params.key +
                                '</span>'
                        )
                        arr.push(itemTemp)
                    }
                } else if (this.defaultValue == 1) {
                    if (itemTemp.label.indexOf(this.params.key) !== -1) {
                        itemTemp.label = itemTemp.label.replace(
                            new RegExp(this.params.key, 'g'),
                            "<span style='color:red;'>" +
                                this.params.key +
                                '</span>'
                        )
                        arr.push(itemTemp)
                    }
                } else if (this.defaultValue == 2) {
                    if (itemTemp.Content.indexOf(this.params.key) !== -1) {
                        itemTemp.Content = itemTemp.Content.replace(
                            new RegExp(this.params.key, 'g'),
                            "<span style='color:red;'>" +
                                this.params.key +
                                '</span>'
                        )
                        arr.push(itemTemp)
                    }
                } else if (this.defaultValue == 3) {
                    if (itemTemp.card.Tags.indexOf(this.params.key) !== -1) {
                        arr.push(itemTemp)
                    }
                }
            })
            this.result = arr
            this.total = this.result.length
        },
        // 循环树结构
        loopTreeData(list, callback) {
            ;(function doOneFloor(list) {
                if (Array.isArray(list)) {
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i]
                        callback(item, i)
                        if (item.children.length > 0) {
                            doOneFloor(item.children)
                        }
                    }
                }
            })(list)
        },
        async getList() {
            this.loading = true
            await this.getTree[this.curType.request](this.params)
                .then(res => {
                    console.log(res)
                    this.result = res.data
                    this.total = res.data.length
                })
                .catch(e => e)
            this.loading = false
        },
        goDetail(item) {
            console.log(item)
            this.$router.push({ name: 'card-info', params: { id: item.Id } })
        },
        // 跳转到模型内的相应部分
        scrollToEle(item) {
            const { card } = item
            const ele = $('#' + this.curType.class + card.Id)
            const parent = ele.parents('.model_main')
            parent.animate({ scrollTop: ele[0].offsetTop }, 500)
        },
    },
    created() {
        this.params.modelId = this.$route.params.id
        this.$store
            .dispatch('card/getCardType')
            .then(res => {
                this.cardType = res
            })
            .catch(e => {
                console.log(e)
            })
    },
}
</script>
<style scoped lang="less">
@import '~@/assets/css/right.less';
</style>