import request from '@/utils/request';

export const AddOrder = function (data) {
    return request({
        url: '/API/User/Order/AddOrder',
        method: 'POST',
        params: data,
    });
};

export const GetOrderInfo = function (data) {
    return request({
        url: '/API/User/Order/GetOrderInfo',
        method: 'GET',
        params: data,
    });
};
