<template>
    <div class="section">
        <div class="tab">
            <div class="item" :class="item.value == curtab ? 'active' : ''" v-for="item in rightTab" :key="item.value"
                @click="changeTab(item)">
                {{ item.name }}
            </div>
            <div class="item itemLast">
                扫码阅读
                <div class="qrdode">
                    <vue-qr :text="qrcodeUrl" :size="150"></vue-qr>
                    <span>扫一扫手机<br />阅读当前页</span>
                </div>
            </div>
        </div>
        <div class="model_info">
            <a-spin :spinning="swdtLoading">
                <div class="swdt">
                    <div class="item" v-if="!isSwdt" @click="isSwdt = true">
                        <svg-icon icon-class="icon_swdt"></svg-icon>
                        <span>导图模式</span>
                    </div>
                    <div class="item" v-else @click="isSwdt = false">
                        <svg-icon icon-class="icon_article"></svg-icon>
                        <span>文章模式</span>
                    </div>
                </div>
                <div class="content">
                    <JsMind :modelType="type" :data="data" :rootName="title" v-if="isSwdt" ref="swdt" />
                    <ModelTree :modelType="type" :data="dataList" :isEditable="true" @showNotes="showNotes"
                        @addFeedback="addFeedback" v-show="!isSwdt" :keyword="keyword" :noteItem="selectedNote" />
                </div>
            </a-spin>
        </div>
        <div class="right" :class="curtab || curtab == 0 ? 'active' : ''">
            <div class="inner">
                <!-- 目录 -->
                <Catelog v-if="curtab == 0" :data="data" :type="type.class" />
                <!-- 检索 -->
                <FilterCard v-if="curtab == 1" :curType="type" :treeData="data" @hightlight="hightlight" />
                <!-- 批注 -->
                <RemarkList v-if="curtab == 3" :type="type.class" />
                <!-- 笔记 -->
                <NotesList v-if="curtab == 4" :curType="type.value" ref="NotesList" :type="type.class"
                    @selectItem="getSelectedNote" @checkItem="getCheckedItem" />
                <!-- 反馈 -->
                <FeedbackList v-if="curtab == 5" ref="FeedbackList" :type="type.class" @selectItem="getSelectedNote"
                    @checkItem="getCheckedItem" />
            </div>
        </div>
        <!-- 笔记 -->
        <NotesDialog :dialogVisable="notesVisable" @close="closeNotesDialog" :info="cardInfo" :selection="selection"
            @success="addNotesSuccess" :end="end" :start="start" :data="checkItem.item" />
        <FeedbackDialog :dialogVisable="feedVisable" @close="closeFeedDialog" :info="cardInfo" :selection="selection"
            @success="addFeedSuccess" :end="end" :start="start" :data="checkItem.item" />
        <!-- 下载 -->
        <DownLoad :loading="confirmLoading" :dialogVisable="downloadDialog" @close="downloadDialog = false"
            @download="download" :model="type" ref="download" :showContent="false" />
    </div>
</template>
<script>
import Catelog from './Catelog.vue'
import FilterCard from './FilterCard.vue'
import NotesList from './NotesList.vue'
import FeedbackList from './FeedbackList.vue'
import ModelTree from '../ModelTree.vue'
import RemarkList from './RemarkList.vue'
import FeedbackDialog from '~/components/Learning/Model/FeedbackDialog'
import NotesDialog from '~/components/Learning/Model/NotesDialog'
import DownLoad from '@/components/Learning/Info/DownLoad'
import JsMind from '~/components/jsmind/Index.vue'
import vueQr from 'vue-qr'

import { ExportWord } from '@/api/export'
export default {
    // props:['type','data'],
    props: {
        type: {
            type: Object,
            default: () => {
                return {
                    name: '',
                }
            },
        },
        data: {
            type: Array,
            default: () => {
                return []
            },
        },

        title: String,
    },
    components: {
        Catelog,
        FilterCard,
        ModelTree,
        FeedbackDialog,
        RemarkList,
        NotesList,
        NotesDialog,
        FeedbackList,
        JsMind,
        DownLoad,
        vueQr,
    },
    computed: {
        qrcodeUrl() {
            return window.location.href
        },
    },
    data() {
        return {
            rightTab: [
                {
                    value: 0,
                    name: '目录',
                },
                {
                    value: 1,
                    name: '检索',
                },
                {
                    value: 4,
                    name: '个人笔记',
                },
                {
                    value: 5,
                    name: '内容反馈',
                },
                {
                    value: 6,
                    name: '分屏查看',
                },
                {
                    value: 7,
                    name: '下载',
                }
            ],
            curtab: null,
            cardInfo: {},
            // 笔记
            notesVisable: false,
            selection: '',
            isSwdt: false,
            // 反馈
            feedVisable: false,
            // 下载
            confirmLoading: false,
            downloadDialog: false,
            swdtLoading: false,
            keyword: '', //高亮关键词
            start: 0,
            end: 0,
            dataList: [],
            noteList: [],
            selectedNote: {},

            // CheckedItem
            checkItem: {},
        }
    },
    watch: {
        curtab(v) {
            this.keyword = ''
        },
        data(v) {
            this.dataList = v
        },
    },
    methods: {
        // 右边切换
        changeTab(item) {
            if (item.value == 6) {
                this.$router.push({
                    name: 'compared-info',
                    params: { id: this.$route.params.id },
                    query: { type: this.type.value },
                })
                return
            }
            if (item.value == 7) {
                this.downloadDialog = true
                return
            }
            if (item.value == this.curtab) {
                this.curtab = null
            } else {
                this.curtab = item.value
            }
        },
        // 关闭笔记弹窗
        closeNotesDialog() {
            this.notesVisable = false
        },
        showNotes(val) {
            const { cardInfo, selection, start, end } = val
            this.cardInfo = cardInfo
            this.selection = selection
            this.notesVisable = true
            this.start = start
            this.end = end
        },
        addNotesSuccess() {
            this.notesVisable = false
            if (!this.$refs.NotesList) return
            this.$refs.NotesList.getList()
        },
        // 反馈
        addFeedback(val) {
            const { cardInfo, selection, start, end } = val
            this.cardInfo = cardInfo
            this.selection = selection
            this.feedVisable = true
            this.start = start
            this.end = end
        },
        closeFeedDialog() {
            this.feedVisable = false
        },
        addFeedSuccess() {
            this.feedVisable = false
            if (!this.$refs.FeedbackList) return
            this.$refs.FeedbackList.getList()
        },
        // 下载
        async download(params) {
            console.log(params)
            this.confirmLoading = true
            const { form, isDownloadSwdt } = params
            if (isDownloadSwdt) {
                const pro = await Promise.all([
                    this.downloadContent(form),
                    this.downloadSwdt(),
                ]).catch(e => {
                    this.confirmLoading = false
                })
                console.log(pro)
            } else {
                await this.downloadContent(form)
            }
            this.confirmLoading = false
            this.downloadDialog = false
        },
        // 下载内容
        downloadContent(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await ExportWord(params).catch(e => e)
                    data.forEach((item, i) => {
                        let link = document.createElement('a')
                        link.href = item.url
                        link.download = item.name
                        link.target = '_blank'
                        setTimeout(() => {
                            link.click()
                            if (i == data.length - 1) {
                                resolve()
                            }
                        }, i * 100)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        // 思维导图
        downloadSwdt() {
            return new Promise(async (resolve, reject) => {
                try {
                    this.swdtLoading = true
                    this.isSwdt = true
                    setTimeout(() => {
                        this.$refs.swdt.screen_shot()
                        this.swdtLoading = false
                        this.isSwdt = false
                        resolve()
                    }, 500)
                } catch (error) {
                    reject(error)
                }
            })
        },
        getSelectedNote(item) {
            this.selectedNote = item
        },
        // 关键词高亮
        hightlight(keyword) {
            this.keyword = keyword
        },
        //
        getCheckedItem(item) {
            this.checkItem = item
            if (item.type == 'note') {
                this.notesVisable = true
            } else {
                this.feedVisable = true
            }
        },
    },
    mounted() { },
}
</script>
<style lang="less" scoped>
.section {
    display: flex;
    border-radius: 10px;
    background-color: #fff;
    height: 100%;
    position: relative;
}

.model_info {
    padding: 20px 20px 50px 40px;
    flex: 1;
    flex-shrink: 1;
    width: 0;
    // width: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .content {
        position: absolute;
        width: 100%;
        height: calc(100% - 50px);
        top: 53px;
        left: 0;
        padding: 0 20px 0 0;
    }
}

.itemLast {
    position: relative;

    .qrdode {
        position: absolute;
        right: calc(100% + 10px);
        background: #fff;
        top: 0;
        padding-bottom: 15px;
        border-radius: 5px;
        z-index: 99;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
        width: 160px;
        color: #333;
        opacity: 0;
        transition: all .4s ease-out;
        transform: translateX(10px);
    }
}

.itemLast:hover {
    .qrdode {
        opacity: 1;
        transform: translateX(0);
    }
}

.swdt {
    margin-bottom: 12px;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        color: rgb(136, 136, 136);
        cursor: pointer;

        span {
            font-size: (14 / 16em);
        }

        .svg-icon {
            font-size: 1em;
            margin-right: 7px;
        }

        &:hover {
            color: #f6a75c;
        }
    }
}

.tab {
    position: absolute;
    left: calc(100% + 5px);
    top: 0;
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-bottom: 1px;
        border-radius: 3px;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(255, 255, 255, 0.2);
        transition: 0.3s;
        padding: 0 10px;
        line-height: 1.2;
        text-align: center;
        cursor: pointer;

        &.active,
        &:hover {
            color: #000;
            background-color: #00ffc0;
        }
    }
}

.right {
    transition: 0.3s;
    width: 0;
    overflow: hidden;
    // width: 300px;
    width: 0;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: rgb(250, 250, 251);
    box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
    background-color: #fafafb;
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 3px;
        background-color: #e5e5e5;
    }

    &::-webkit-scrollbar-thumb {
        width: 100%;
        border-radius: 3px;
        background-color: #f6a75c;
    }

    .inner {
        width: 300px;
        padding: 20px 0 20px 20px;
        position: absolute;
        left: 0;
        height: 100%;
    }

    &.active {
        width: 300px;
    }
}

@media (max-width: 1024px) {
    .model_info {
        padding: 0.2rem 0.15rem;

        .content {
            padding: 0;
        }
    }

    .tab {
        display: none;
    }

    .swdt {
        display: none;
    }
}
</style>
