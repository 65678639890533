<template>
    <a-modal
        v-model="feedVisable"
        centered
        :width="800"
        :footer="null"
        class="addDialog"
        @cancel="close"
        :destroyOnClose="true"
        :closable="false"
        :confirm-loading="confirmLoading"
    >
        <div class="dialog_header">
            <h2>内容反馈</h2>
            <div class="close">
                <svg-icon
                    icon-class="icon_close"
                    @click.native="close"
                ></svg-icon>
            </div>
        </div>
        <div class="dialog_content">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label">记录的卡片名称：</div>
                        <div class="input">{{ form.Title }}</div>
                    </div>
                </a-form-model-item>
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label">选中的内容：</div>
                        <div class="input">
                            <div class="content" v-html="form.Txt"></div>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="Note">
                    <div class="form-item">
                        <div class="label">内容：</div>
                        <div class="input">
                            <a-textarea
                                v-model="form.Note"
                                :auto-size="{ minRows: 4, maxRows: 6 }"
                                :maxLength="2000"
                            />
                            <div class="limit">
                                {{ form.Note?.length }}/2000
                            </div>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label">颜色：</div>
                        <div class="input">
                            <div class="color-picker">
                                <div
                                    class="color"
                                    v-for="item in colorList"
                                    :style="'background-color:' + item + ';'"
                                    @click="form.Color = item"
                                    :title="item"
                                ></div>
                                <el-color-picker
                                    v-model="form.Color"
                                ></el-color-picker>
                            </div>
                        </div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="dialog_footer">
            <a-button
                class="confirm"
                :loading="confirmLoading"
                @click="confirmDownload"
            >
                确认
            </a-button>
            <a-button class="cancel" @click="cancelDownload">取消</a-button>
        </div>
    </a-modal>
</template>
<script>
import { Base64 } from 'js-base64'
import { AddFeedback, AddNote } from '@/api/card'
import elColorPicker from 'el-color-picker'
export default {
    props: {
        dialogVisable: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Object,
            default: {
                label: '',
            },
        },
        selection: String,
        modelId: [String, Number],
        start: [String, Number],
        end: [String, Number],
        data: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        elColorPicker,
    },
    data() {
        return {
            confirmLoading: false,
            feedVisable: false,
            value: '',
            form: {
                Color: '#ff0000',
                CardId: 0,
                modelId: 0,
                StartIndex: 0,
                EndIndex: 0,
                Title: '',
                Txt: '',
                Note: '',
            },
            rules: {
                Note: [
                    {
                        required: true,
                        message: '请输入反馈内容',
                        trigger: 'blur',
                    },
                ],
            },
            colorList: ['#2DC26B', '#F1C40F', '#E03E2D', '#B96AD9', '#3598DB'],
        }
    },
    watch: {
        dialogVisable(v) {
            this.feedVisable = v
        },
        data(v) {
            this.form = JSON.parse(JSON.stringify(v))
        },
        selection(v) {
            this.form.Txt = v
            this.form.Color = '#ff0000'
            this.form.CardId = this.info.card?.Id || this.info.Id
            this.form.modelId =
                this.$route.name == 'card-info'
                    ? this.$route.query.modelId || 0
                    : this.$route.params.id
            this.form.StartIndex = this.start
            this.form.EndIndex = this.end
            this.form.Title = this.info.label || this.info.Name
            this.form.Note = ''
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        confirmDownload() {
            console.log(this.form)
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    const form = {
                        Title: this.info.label || this.info.Name,
                        modelId:
                            this.$route.name == 'card-info'
                                ? this.$route.query.modelId || 0
                                : this.$route.params.id,
                        CardId: this.info.card?.Id || this.info.Id,
                        Txt: this.selection,
                        Note: this.form.value,
                        StartIndex: this.start,
                        EndIndex: this.end,
                        Color: this.form.Color,
                    }
                    console.log(form)
                    AddFeedback(Base64.encode(JSON.stringify(this.form)))
                        .then(res => {
                            console.log(res)
                            this.$message.success('添加成功')
                            this.form.value = ''
                            this.confirmLoading = false
                            this.$emit('success')
                        })
                        .catch(err => {
                            console.log(err)
                            this.confirmLoading = false
                        })
                } else {
                    return false
                }
            })
        },
        cancelDownload() {
            this.$emit('close')
        },
    },
}
</script>
<style lang="less" scoped>
:deep(.ant-modal-body) {
    padding-left: 50px;
    padding-right: 50px;
}
:deep(canvas) {
    position: relative;
}
:deep(.c__panel) {
    left: 0;
    top: auto;
    bottom: 0.4rem;
}
:deep(.c__color-picker) {
    cursor: pointer;
}
.form-item {
    display: flex;
    font-size: 18px;
    color: rgb(85, 85, 85);
    line-height: 1.667;
    margin-bottom: 10px;
    .label {
        width: 170px;
        flex-shrink: 0;
    }
    .input {
        flex: 1;
        position: relative;
    }
    .limit {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #999;
    }
    .content {
        max-height: 200px;
        overflow-y: auto;
    }
}
.ant-input {
    width: 100%;
    margin-top: 5px;
    font-size: 18px;
    color: rgb(85, 85, 85);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 30px;
}
.color-picker {
    display: flex;
    align-items: flex-start;
    .color {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .c__color-picker {
        margin-left: 20px;
        .c__block {
            width: 32px;
            height: 32px;
        }
    }
    :deep(.c__block) {
        width: 32px;
        height: 32px;
    }
}
</style>
